import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Card, Heading, Flex } from 'reakit'

import { Container, Body, Form, TopBar, HomeFooter } from '../components/layouts'

import { Link } from '../components/ui'

import Layout from '../components/layout'

import { login } from '../util/auth'

import { clearAuth } from '../factory/auth'

import theme from '../theme/content'

class Login extends React.Component {
	constructor(props) {
		super(props)
		this.content = theme.pagesContent.login

		this.state = {
			formValidate: null,
			logging: null,
		}

		this.setValues = this.setValues.bind(this)
		this.submitForm = this.submitForm.bind(this)
	}

	componentDidMount() {
		this.props.clear()
	}

	setValues(e) {
		const target = e.target
		const name = e.target.name
		const value = e.target.value

		if (target) {
			this.setState({
				[name]: value,
			})
		}
	}

	submitForm(e) {
		this.setState({
			logging: true,
			formValidate: null
		})
		e.preventDefault()

		var ua = navigator.userAgent.toLowerCase()
		if (ua.indexOf('chrome') === -1) {
			alert("Please be aware that some functionality may be limited on your browser. Flourish is fully supported on Chrome.")
		}

		if (this.state.email && this.state.password) {
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
				this.props.onLogin(this.state.email, this.state.password)

				this.setState({
					logging: true,
				})
			} else {
				this.setState({
					formValidate: "Please use the correct email format.",
					logging: false,
				})
			}
		} else {
			this.setState({
				formValidate: "Please fill the form.",
				logging: false,
			})
		}
	}

	render() {
		return (
			<Layout>
				<TopBar />
				<Container maxHeight="100vh">
					<Body bgColor={'transparent'} bg={this.content.bg} maxWidth="100%">
						<Flex
							width={'100%'}
							height={'100vh'}
							alignItems={'center'}
							justifyContent={'center'}
							flexDirection={'column'}
						>
							<Card
								background={'transparent'}
								boxShadow="none"
								width="35%"
								minWidth="350px"
							>
								<Heading as="h1" margin="0 0 2rem 0">
									{this.content.title}
								</Heading>
								<Flex margin="0 0 -0.5rem 0" column>
									<Form
										fields={this.content.loginForm.fields}
										setValues={this.setValues}
										submitForm={this.submitForm}
										submitBtnText={this.content.loginForm.submitField.text}
										logging={this.state.logging || this.props.pending}
										error={this.props.error || this.state.formValidate}
									/>
								</Flex>
								<Flex margin="0" row>
									<Heading as="h6" margin="0 0.5rem 0 0">
										<Link
											as="a"
											href={this.content.register.link}
										>
											{this.content.register.text}
										</Link>
									</Heading>
									<Heading as="h6" margin="0 0.5rem 0 0">
										•
									</Heading>
									<Heading as="h6" margin="0">
										<Link as="a" href={this.content.forgot.link}>
											{this.content.forgot.text}
										</Link>
									</Heading>
								</Flex>
							</Card>
						</Flex>
					</Body>
				</Container>
				<HomeFooter />
			</Layout>
		)
	}
}

Login.propTypes = {
	onLogin: PropTypes.func,
	clear: PropTypes.func,
	notifyMessage: PropTypes.func,
	accessToken: PropTypes.string,
	error: PropTypes.any,
	pending: PropTypes.bool,
}

const mapStateToProps = state => {
	return {
		accessToken: state.authState.accessToken,
		error: state.authState.error,
		pending: state.authState.pending,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		clear: () => dispatch(clearAuth()),
		onLogin: (email, password) => dispatch(login(email, password)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Login)
